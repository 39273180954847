.sponsor_container .head {
    text-align: center;
    margin: 0 auto !important;
}

.sponsor_container {
    height: 600px;
    padding: 20px;
    display: block;
    justify-content: center;
    align-items: center;
    background: #fffeee;
}
.sponsor_link{
    text-decoration: none;
}

.sponsor_img {
    width: 400px;
    height: 300px;
    margin: 0 auto !important;
    display: flex;
    padding: 20px;
    justify-content: center;

}
.click_btn{
    text-align: center;
}

.company {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
}

.sponsor {
    text-align: center;
}

.underline {
    margin: auto !important;
    width: 40%;
    border: 2px solid #D1363F;
    background-color: #D1363F;

}