.artists-title {
   text-align: center;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 98px;
    letter-spacing: 0.1em;
    color: #000000;
    opacity: 0.3;
}