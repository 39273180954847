img {
  height: 500px;
  width: 100%;
  vertical-align: middle;
}

.card-container {
  max-width: 100%;

}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 100%;
}



.cards_item {
  display: flex;
  padding: 10px;


}

/* .cards_item:hover {
  transform: scale(0.9) translateZ(0);

} */

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 33.3333%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 1rem;
  background-color: #fff;
}

.card_title {
  color: #000;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  overflow-y: hidden;
}

.card_text {
  color: #000;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  font-weight: 400;
}

.card_text_link {
  font-weight: bold;
  text-decoration: none !important;

}

.link {
  text-decoration: none !important;
  color: #000;
  font-weight: bold;
}


.view_event {
  border: none;
  width: 109px;
  height: 36px;
  color: #fff;
  background: #000000;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card_text_about {
  color: #000;
  font-size: 0.875rem;
  margin-top: 26px;
  font-weight: 400;
}

.card_grid {
  display: grid;
  grid: 1fr / 1fr 1fr;
}

.grid-row-1 {
  display: grid;
  grid: 1fr /1fr;
  justify-items: center;
  align-items: start;
}

.grid-row-2 {
  display: grid;
  grid: 1fr /1fr;
  justify-items: center;
  align-items: end;

}

/* .float_child {
  float: left;
} */
.float_container {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;


}

.float_container .view_event_btn {
  margin-right: 20px !important;
}