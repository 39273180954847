.contact-page {
    width: 531px;
    font-family: 'Nunito Sans', sans-serif !important;
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    text-align: center !important;
    line-height: 98px;
    margin: 0 auto !important;
    letter-spacing: 0.1em;
    color: #000000;
    opacity: 0.3;
}

.contact-card {
    width: 50%;
    margin: 30px auto !important;

}

.submit_btn {
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    padding: 10px 20px !important;
    width: 119px !important;
    height: 45px !important;
    color: #fff !important;
    background: #000000 !important;
    border-radius: 5px;
    margin: 0 auto !important;
    justify-content: center;
}

/* .btn-primary {
    color: #fff;
    background-color: #000;
    margin: 0 auto !important;
} */
.facebook_icon{
  text-align: center !important;
}

@media screen and (max-width: 422px) {
    .contact-card {
        width: 90%;
        margin: 30px auto !important;
    }

    .contact-page {
        font-size: 50px;
        overflow-x: hidden;
    }

}