.section_view {
    background-image: url("../../images/events.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    z-index: -1;
}
.container_event{
    margin: 0!important;
    padding: 0;
}
.btn_link{
    text-decoration: none;
}
.view_artists_btn {
    margin: 0 auto !important;
    display: flex;
    flex-direction: row;
    color: #fff;
    border: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 237px;
    height: 45px;
    background: #000000;
    border-radius: 5px;
    text-decoration: none !important;
   

}
.view_artists_btn:hover {
    background-color: #D1363F;
}

.view_all_show_dates {
    margin: 0 auto !important;
    display: flex;
    flex-direction: row;
    color: #fff;
    border: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    width: 237px;
    height: 45px;
    background: #000000;
    border-radius: 5px;
    position:absolute;
    z-index:2;
    right: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    margin-top:30px !important;

}
.view_all_show_dates:hover {
    background-color: #D1363F;
}




.card_event {
    position: relative;
    background-color: #fff;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    word-wrap: break-word;
    width: 400px;
    box-shadow: #000;
    margin: 0 auto !important;
    justify-content: space-between;
    padding: 20px !important;
    justify-content: space-between !important;

}



.desk h3 {
    color: #000;
    font-size: 15px;
    font-weight: 700;

}
.card_group_event {
    padding: 10px !important;
    margin: 0 auto !important;
}

.desk p {
    color: #000;
    margin-top: 10px;
    
}
.desk .event_date{
    color: #000;
    margin-top: 10px;
    font-weight: 700;
}

.btn {
    margin-top: 10px;
}
/* .btn:hover {
    background-color: #D1363F;
} */

.btn a {
    padding: 5px 10px;
    background-color: #000;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;

}

.btn a:hover {
    background-color: #D1363F;
}
.black_bg{
    position:absolute;
    opacity:0.8;
    background-color: #000;
    height:500px;
    width: 100%;
    
}
/* .data_card .btn{
    display: flex;
    justify-content:end;
} */


/* .data_card {
    display: flex !important;
    font-size: 15px;
}
.data_card p{
    font-size: 13px;
    font-weight: bold;
} */

.card_group {
    padding: 10px !important;
    margin: 0 auto !important;
}
.card_group_single{
   margin:auto !important;
    padding: 10px !important;
    
}

@media screen and (max-width: 760px) {
    .card_event {

        margin-top: 10px !important;
    }

    .section_view {
        background-color: #000 !important;
        height: 1300px;
    }
    .artists{
        margin-top: -168px;
        font-size: 100px;
    }

}
@media screen and (max-width: 760px) {
    
    .artists{
        margin-top: -168px !important;
        font-size: 100px !important;
    }

}

.artists {
    position: absolute;
    z-index: -1;
    text-align: center !important;
    width: 100%;
    margin-top: -180px;
    font-style: normal;
    font-weight: 800;
    font-size: 288px;
    line-height: 393px;
    color: #000000;
    opacity: 0.1;
    overflow-x: hidden;
}
