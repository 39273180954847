.container {
    width: 100%;
    height: 450px;
    max-width: 100%;
  
}

.videoTag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
    max-width: 100%;
    z-index: -1;
}

.hero-text{
    padding-top: 150px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
}
.hero-desc{
    color: #fff;
    font-size: 20px;
}
.tickets{
    color: white;
    background-color: #D1363F;
    border-radius: 5px;
    font-size: 18px;
    border: none;
    width: 222px;
    height: 40px;
    /* position: absolute; */
}
.tickets:hover{
    background-color: #000;
}

@media only screen and (max-width: 350px) {
    .hero-text{
        padding-top: 130px;
        font-size: 20px;
        
    }
    .hero-desc{
        font-size: 15px;
        
    }
  }