.events_title {
    width: 531px;
    height: 118px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    text-align: center !important;
    line-height: 98px;
    margin: 0 auto !important;
    letter-spacing: 0.1em;
    color: #000000;
    opacity: 0.3;
}
.events_title-past{
    width: 631px;
    height: 118px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    text-align: center !important;
    line-height: 98px;
    margin: 0 auto !important;
    letter-spacing: 0.1em;
    color: #d1363f;
    opacity: 0.3;
    /* margin-top: -120px !important; */
}

.card_group_event {
    padding: 10px !important;
    margin: 0 auto !important;

}

.card_event_event {
    width: 580px;
    height: 107px;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    position: relative;
    background-color: #fff;
    display: flex;
    padding: 5px;
    border-radius: 5px;
    word-wrap: break-word;
    width: 400px;
    box-shadow: #000;
    margin: 5px auto !important;
    justify-content: space-between;
    padding: 10px !important;
}

.section_view_event {
    background-color: #fff;
    height: 500px;
}

@media screen and (max-width: 760px) {
    .card_event_event {
        margin-top: 10px !important;
    }
    .section_view_event{
        height: 1300px;
    }

}