.footer_data {
    align-items: center;
    background-color: black;
    text-align: center;
    width: 100%;
    height: 400px;

}

.footer_title {
    color: #fff;
    padding-top: 60px;
    font-size: 24px;
    font-weight: bold;
    position: relative;
}

.footer_desc {
    color: #fff;
    font-weight: regular;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer_input {
    background-color: #000;
    border: 1px solid #D1363F;
    padding: 5px;
    color: #fff;
    width: 400px;
    height: 40px;
    border-radius: 5px;
    padding: 20px;
    text-align: start;
    justify-content: center;

}

.btn_subscribe {
    color: #fff;
    background-color: #D1363F;
    width: 130px;
    height: 40px;
    align-items: flex-start;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 20px;

}

.hr_line {
    padding-left: 20px;
    padding-right: 20px;
    height: 2px;
    background-color: #D1363F;
    margin-left: 30px;
    margin-right: 30px;
    border: 2px solid #D1363F;
}

.copyright {
    text-align: center;
    color: #fff;
    padding-top: 60px;
}
@media screen and (max-width: 422px) {
    .footer_input{
        width: 300px;
    }
    
       
}